@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

::-webkit-scrollbar {
  display: none;
}

.highlight-article{
  padding: 20px 30px;
}
.highlight-article img{
  max-height: 340px;
  width: 100%;

}

.cardnews1  img{
  border-radius: 10px;
}


.navbar-toggler:focus{
  box-shadow: none!important;
}

.fa-circle{
  font-size: 8px!important;
top: -1px;
position: relative;
}
a{
  text-decoration: none!important;
}

.logowkl{
  width: 115px;
  padding: 6px;

}
body{
  /* background-image: url('./bodybg.png'); */
  background-color: #211C65;
}

.center-logo  img{
   width: 130px;
  position: relative;
  z-index: 99;
  top: 26px; 
}
/* header */
nav{
background: linear-gradient(360deg, rgba(62, 58, 114, 1) 22%, rgba(38, 34, 94, 1) 65%);
  border-top: 1px solid #ffffff36;
    margin-top: 5px;
}

.nav-link{
  padding-left: 0px;
}
.col-11 {
  margin-left: 3%;
    width: 88%;
}

nav li a{
  font-family: 'poppins';
  color: #fff !important;
  font-size: 14px!important;
    font-weight: 500!important;
    margin-right: 20px;
    padding-bottom: 0px!important;
}

.abstractnav

{
  width: 40px;
  float: right;
    right: 0px;
    position: absolute;
}

.marquee {
  overflow: hidden;
  white-space: nowrap;
  line-height: 5px;
}
.marquee span {
  display: inline-block;
  animation: marquee 16s linear infinite;
}
@keyframes marquee {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}
#preheader .text-right i {
  color: #fff;
  text-decoration: none;
  list-style-type: none;
  margin-left: 20px;
}


.navbar {
  margin:0px;
  border: 0px;
  height: 60px;
}


/* preheader */
#preheader{
  background-color: #DB0A73;
  padding: 5px;
}
#preheader span{
  font-family: 'poppins';
  color: #fff;
  font-size: 12px;
}




/* countdown */
.fixturecard{
  background-image: url('./Home/fixtureb.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.teamname h2{
  color: #fff;
  font-size: 16px;
  font-family: "Oswald", sans-serif;

  text-transform: uppercase;
}

.teamname h5{
  color: #fff;
  font-family: "Oswald", sans-serif;
  text-align: center;
  font-size: 26px;
  color: #F07C28;
}
.teamname {
  padding: 20px;
}
.right-fixture img{
  width: 60px;
  margin: auto;
  display: block;
}

#countdown{
background-color: #15134b;
padding: 60px 10px;
}
.circle-countdown {
  display: block;
    float: right;
    right: 0px;
    position: relative;
  width: 240px;

  height: 240px;
  border-radius: 50%;
  border: 2px solid #000; /* Optional: Adds a border */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  background-color: #1E1A57; /* Optional: Background color */
}
.term-condit p{
  width: 100%!important;
  margin-bottom: 20px;
  font-weight: 400!important;
}
.time  h4{
  font-family: 'Oswald';
    font-size: 16px;
    color: #fff;
    font-weight: 400;
}
.circle-countdown .countdown h6{
  font-family: 'poppins';
  color: #fff;
}
.time-fixture h4{
  font-family: 'oswald';
  color: #fff;
}
.circle-countdown .time h4,
.circle-countdown .countdown h6,
.circle-countdown .time-fixture h4,
.circle-countdown .time-fixture h5 {
  margin: 5px 0;
}

.circle-countdown .countdown h6 {
  font-size: 1.2em;
  font-weight: bold;
}

.circle-countdown .time-fixture h5 {
  font-size: 0.9em;
}


/*  footer section */

.footersection{
  background-color: #211C65;
  padding: 50px;
}
.footer-sociallinks{
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  
}
.footer-sociallinks li{
  list-style-type: none;
  text-decoration: none;
}
.footer-sociallinks li a{
  color: #fff;
  margin-left: 25px;
  font-size: 18px;
  border-radius: 10px;
}

.footer-navlink{
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;

}
.footersection img
{
  margin: auto;
  display: block;
  margin-top: 30px;
}

.readmore {
  color: #fff;
  font-family: 'oswald';
  font-size: 14px;

}
.readmore  p{
  margin-top: 10px!important;
  margin-bottom: 6px;
  font-family: 'outfit';
}
.footer-navlink  li{
  list-style-type: none;
  margin-top: 20px;
  text-decoration: none;
}

.footer-navlink li a{
  color: #fff;
  margin-left: 40px;
  font-size: 16px;
  text-decoration: none;
  font-family: "Outfit", sans-serif;

}


/* banner */



.carousel-item {
  position: relative;
  height: 80vh;
  background-size: cover;
  background-position: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: linear-gradient(to top, rgb(33, 28, 101) 11%, rgba(42, 29, 95, 0.007) 100%); */
  z-index: 1;
}

.content {
  position: absolute;
  bottom: 80px;
  left: 50px;
  color: white;
  z-index: 2;
}
.carousel-indicators{
  left:40px!important;
  bottom: 30px!important;
  margin-right: 0%!important;
  margin-left:  0%!important;
  padding-left: 0px!important;
  justify-content: left;
}


.content  h5{
  font-size: 38px;
  font-family: "Oswald", sans-serif;
  text-transform: capitalize;
  line-height: 50px;
  width: 70%;

}
.col-md-11{
  margin-left: 2%;
  width: 89%;
}
.carousel-indicators .active {
  background-color: transparent;
  border: 2px solid white; /* Circle outline for active item */
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.carousel-indicators li {
  background-color: white; /* Default indicator color */
  border-radius: 50%;
  width: 12px;
  height: 12px;
}


.carousel-indicators .active{
height: 10px;
border-radius: 10px;
width: 50px;
background: #ffffff78;
border: 0px;
}

.carousel-indicators li{
  height: 10px;
border-radius: 10px;
width: 10px;
margin-left: 10px;
background: #ffffff78;
border: 0px;
}
::marker{
  display: none!important;
}
.carousel-control-next-icon , .carousel-control-prev{
  display: none!important; 
}


/* Hide the expanded row by default */
.expanded-row {
  display: none;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

/* Show the expanded row */
.expandable-row:hover + .expanded-row,
.expanded-row {
  display: table-row;
  opacity: 1;
  transform: translateY(0);
}

/* Expand/Collapse icon style */
.expand-icon {
  font-size: 24px;
  text-align: center;
  cursor: pointer;
}

.expandable-row {
  transition: background-color 0.3s ease;
}

/* Hover effect for row */
.expandable-row:hover {
  background-color: #f9f9f9;
}

.caption img{
  width: 30px!important;
}
.center-expand p{
  text-transform: uppercase;
  color: #001F54;
  margin-bottom: 0px;
}
/* Transition for content within the expanded row */
.expanded-content {
  transition: opacity 0.5s ease;
}




/* newcard */
#newscard{
  padding: 30px 0px 40px 20px;
  background-color: #fff;
}
#newscard  .heading p{
font-size: 18px;
}
.cardnews{
  background-color:#DB0A73;
  padding: 7px;
  border-radius:10px;
}
.cardnews img{
  border-radius: 10px;
  margin: auto;
  display: block;
}

.news-caption .date{
  color: #ffffffc7;
  font-family: 'poppins';
  font-size:14px;
  margin-bottom: 0px!important;
    margin-top: 13px;
}
.news-caption .date p{
  margin-bottom: 0px;
}

.news-caption .heading{
  color: #fff;
  margin-top: 10px;
  font-size: 20px;
  font-family: 'oswald';
  text-transform: capitalize;
  font-weight: 400;
}

#highlights{
  background-color: #17144a;
  padding: 30px 0px 20px  30px;
}


.hightlightlist{
  background-color: #fff;
  margin-bottom: 20px;
  border: 1px solid #fff;
}
.hightlightlist .col-4{
  padding-right: 0px;
}
.hightlight-caption{
  padding: 12px 15px 12px 5px;
}
.hightlight-caption .date {
font-family: 'poppins';
font-size: 12px;
color: #211C65;
margin-top: 5px;
margin-bottom: 8px;
font-weight: 400;
}



.hightlight-caption h5 {
  font-family: 'oswald';
  font-size: 18px;
  color: #211C65;
  font-weight: 600;
  line-height: 22px;
  }
  .readmore-highlights p{
    font-family: 'oswald';
    margin-bottom: 0px;
    margin-top: 16px;
    color: #211C65;
  }

  .highlights-tabs p{
    background-color: #db0a73;
    color: #fff;
    margin-bottom: 5px;
    font-family: 'oswald';
    font-weight: 300;
    width: fit-content;
    font-size: 10px;
    padding: 2px 10px;
    border-radius: 10px;
    /* margin-top: 10px; */
  }
  .left-highlight{
    background-color: #fff;

  }
  .left-highlight  .date{
    color: #211C65;
    font-family: 'poppins';
    font-size: 12px;
  }
  .left-highlight img{
    width: 100%;
  }
  .left-caption{
    padding: 15px;
  }

  .left-caption .highlights-blink
  {
    background-color: #db0a73;
    color: #fff;
    margin-bottom: 5px;
    font-family: 'oswald';
    font-weight: 300;
    width: fit-content;
    font-size: 10px;
    padding: 2px 10px;
    border-radius: 10px;
  }

  #reels{
    padding: 30px 20px;
  }

  .img-left{
    border-radius: 5px;
  }
  .owl-themevideo img{

    border-radius: 10px;
    border: 1px solid #f07c2863;
  }

  .VIDEOABOUT1{
    width: 60%;
    border-radius: 50px;
    margin: auto;
    display: block;
    border: 2px solid #F07C28;
    margin-bottom: 30px;
    
  }
.VIDEOABOUT{
  border-radius: 20px;
  width: 80%;
  margin: auto;
  display: block;
}
  .left-caption .heading h4{
    font-size: 18px;
    color: #211C65;
    width: 95%;
    font-family: 'oswald';
    font-weight: 600;
  }
.VIDEOABOUT3{
  width: 100%;
  border-radius: 5px;
}
  .left-caption .heading p{
    font-size: 15px;
    color: #211C65;
    width: 100%;
    font-family: 'poppins';
    font-weight: 400;
    margin-top: 15px;
    margin-bottom: 0px;
  }



  /* copyright */

  .copy-right {
padding: 20px;
background-color: #211C65;
border-top: 1px solid #ffffff2b;
  }
  .copy-right img{
    width: 70px;
    margin: auto;
    display: block;
  }
  .copy-right p{
    color: #fff;
    margin-bottom: 0px;
    font-family: 'poppins';
    font-weight: 400;
    margin-top: 20px;
    font-size: 15px;
    text-align: center;
  }


  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  /* background: linear-gradient(to top, rgb(33, 28, 101) 11%, rgba(42, 29, 95, 0.007) 100%); */
}
  

  .owl-theme .owl-nav.disabled + .owl-dots{
    margin-top: -60px!important ;
  }
  
  .owl-dots {
    position: absolute;
    width: 100%;
    display: flex;
    margin-left: 50px;
  }
  

  .owl-dots .active span{
    background-color:#ffffff78!important;
    border-radius:10px;
    width: 40px!important;
    height: 12px;

  }

  .owl-dot span{

    height: 10px;
     background-color:#ffffff78!important;
    border-radius: 10px;
    width: 10px;
    margin-left: 10px;
    background: #ffffff78;
    border: 0px;
  }

  .owl-dots .owl-prev,
  .owl-dots .owl-next {
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    color: #ffffff78; /* Arrow color */
    border: none; /* Remove borders */
    border-radius: 50%; /* Round buttons */
    width: 40px; /* Button size */
    height: 40px; /* Button size */
    display: flex;
 
    cursor: pointer;
  }
  
  .owl-dots .owl-prev {
    left: 10px; /* Position left */
  }
  
  .owl-dots .owl-next {
    right: 10px; /* Position right */
  }
  






  /* blog page */
  #blog-page{
    padding: 10px 20px;
    background-color: #26225E;
  }


  #blog-page .left-highlight{
    margin-bottom: 30px;
  }




















  

/* standing */

#standing{
  background-image: url('./BG11.jpg');
  background-color: #211C65;
  padding: 40px 80px;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* background-position: right; */
}
.headingstanding h4{
  color: #fff;
  font-size: 32px;
  text-align: center;
  font-family: 'Oswald';
  font-weight: 700;
  margin-bottom: 50px;
}

.custom-header th{
  background-color: #fff;
  border: 0px;
}
.custom-header {
  background-color: #F07C28; /* Yellow background */
  color: #fff; /* Black text */
}
.bogimage{
  margin: 30px 0px;
}

.custom-table tr:nth-child(even) {
  background-color: #fff; /* White background for odd rows */
}

.custom-table tr:nth-child(odd) {
  background-color: #f2f2f2; /* Light gray background for even rows */
}
.table ul{
  list-style-type: none;
  justify-content: center;
}

.form-win {
  background-color: green;
  color: white;
  padding: 5px 10px;
  border-radius: 30px;
  margin-right: 10px;
  font-family: 'poppins';
  width: 28px;
  height: 28px;
  line-height: 15px;
  left: 0px;
}
.text-left{
  text-align: left!important;
}
.form-loss {
  background-color: red;
  color: white;
  padding: 5px 10px;
  border-radius: 30px;
  margin-right: 10px;
  font-family: 'poppins';
  width: 28px;
  height: 28px;
  line-height: 15px;
  left: 0px;
}

.form-toss {
  background-color: #aaaaaa80;
  color: white;
  padding: 5px 10px;
  border-radius: 30px;
  margin-right: 10px;
  font-family: 'poppins';
  width: 28px;
  height: 28px;
  line-height: 15px;
  left: 0px;
}
.form-win p{
  left: -3px;
  position: relative;
  font-family: 'outfit';
  top: 1px;
  font-weight: 400!important;
}

.form-toss p{
  left: 0px;
  position: relative;
  font-family: 'poppins';
  top: 2px;
  font-weight: 400!important;
}
.form-loss p{
  left:0px;
  position: relative;
  font-family: 'outfit';
  top: 0px;
  font-weight:500!important;
}
.tablwidth{
  width: 25%;
}
.tablwidth1{
  width: 15%;
}

.form-tie {
  background-color: grey;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

thead {
  border-bottom-width: 10px;
  border-color:#211C65 !important;
  background-image: url('./Rectangle 157.png');
  background-repeat: no-repeat;
}

.custom-header th{
  font-size: 14px;
  font-weight: 500;
  color: #211C65;
  text-transform: uppercase;
  font-family: 'oswald';
  text-align: center;
}

.table  td{
  font-size: 14px;
  font-weight: 500;
  text-align: center; 
    vertical-align: middle;
  color: #211C65;
  font-family: 'oswald';
  text-align: center;
  text-transform: uppercase;
  padding: 2px;
  border: 0px;
}

td img {
  width: 46px !important;
}











.desktopshow{
  display: block!important;
}
.mobileshow{
  display: none!important;
}
.breadcrumb {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.breadcrumb-image {
  width: 100%; /* Adjust as needed */
  height: auto;
}

.eroortext{
  color: red;
}

.term-condit{
  margin-top:30px;
}

.form-group1 input:focus-visible {
    outline: none; /* Remove default outline */
    border-color: #F07C28; /* Customize border color */
    transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}
.breadcrumb-text {
  position: absolute;
  left: 10px; /* Adjust spacing from left */
  top: 50%;
  transform: translateY(-50%);
  color: white; /* Change color as needed */
  font-size: 36px;
    left: 40px;
    font-family: 'Oswald';
    font-weight: 500;
    text-transform: uppercase;
}

th:first-of-type {
  width: 4% !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

}

th:last-of-type {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

}

.navbar-toggler-icon {
  background-image: url('../menu.png');
}



  /* team section */

.bang{
  background-image: url('./BENGALUR.jpg');
}
  .Punjab{
    background-image: url('./MARATHAS.jpg');
  }
  .UP{
    background-image: url('./upimage.jpg')!important;
  }

  .Tel{
    background-image: url('./TELUGU (1).jpg')!important;
  }

  .Rajasthan{
    background-image: url('./RAJASHAN.jpg')!important;
  }
  
.text-white{
  font-family: 'Oswald';
    text-align: center;
    margin-bottom: 33px;
}
#aboutus{
  padding: 40px 30px;
}

.logo-slider{
  padding:40px 40px 20px 40px;
}



.caption{
  color: #fff;
  text-transform: capitalize;
  padding: 5px 10px!important;
  width: fit-content;
  font-family: 'Oswald';
  font-size: 15px;
  font-weight: 400;
  border-radius: 5px;


}

.left-table{
  border: 1px solid #f07c28;
  padding: 5px;
  border-radius: 5px;
  width: fit-content;
}
.left-table2{
margin: auto;
}

.caption p{
  margin-bottom: 0px;
}
#aboutus1{
  padding: 70px 20px 40px  20px;
  background-position: bottom;
  background-repeat: no-repeat;

}

#aboutus img{
border-radius: 5px;
}
#aboutus h4{
  margin-bottom: 30px;
  color: #fff;
  font-family: 'oswald';
  font-size: 25px;
}
#aboutus p{
  color: #fff;
  font-family: 'poppins';
  width: 90%;
  font-weight: 300;
  font-size: 15px;
}
  
  .Guj{
    background-image: url('./GUJARAT\ \(3\).jpg')!important;
  }
  

  .Har{
    background-image: url('./HARYANA (1).jpg')!important;
  }
  


  .delhi {
    background-image: url('./DELHI\ \(1\).jpg')!important;

  }
  .widthfit{
    width: fit-content;
  }
  .linecard{
    background-color: #F4C30D;
    height: 1.5px;
    width: 100%;
  }

.Bengaluru{
  background-image: url('./IMG/bengaluru.jpg')!important;

}
  .Haryana{
    background-image: url('./IMG/haryana.jpg')!important;
  }
  .Gujarat{
    background-image: url('https://dev-ipkl.ipkl.in/files/Gray%20frame.png')!important;
  }
.teamnewcard{
  margin-bottom: 50px;
  margin: 10px;
}



  #teamsection{
    padding: 50px 10px 30px 10px;
    /* background-image: url('./smokebg.png')!important; */
    background: #20204F;
  }
  .team-card-bengaluru-bison {
    position: relative;
    margin-bottom: 20px;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    padding:30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    color: white;
}

.team-card-heading {
    font-size: 26px;
    font-family: 'oswald';
    margin-top: 15px;
}

.team-card-underline {
    border: 0;
    height: 2px;
    background-color: #FFD700; /* Golden color for the line */
    width: 60px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.team-card-buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 30px;
    margin-bottom: 10px;
}

.team-card-btn {
    padding: 7px 15px;
    width: 35%!important;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    width: fit-content;
    font-family: 'poppins';
}

.team-card-matches-btn {
    background-color: white;
    color: #001F54;
}

.team-card-matches-btn:hover {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  transition:1s ease;
}

.team-card-profile-btn {
    background-color: transparent;
    color: white;
    border: 1px solid white;
}


.team-card-profile-btn:hover {
  background-color: white;
  color: #20204f;
  transition:1s ease;
  /* border: 1px solid white; */
}

.team-card-logo-corner {
    position: absolute;
    bottom: 0px;
    right: 0px;
}


.team-card-logo {
  position: relative;
  transition: transform 0.8s ease-in-out;
}

.team-card-logo {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  border: 2px solid white;
  position: relative;
  top: -26px;
  left: 74px;
  z-index: 0;
}

/* Hover effect */
.team-card-bengaluru-bison:hover .team-card-logo {
  transform: translate(-20px, -20px); /* Move the logo corner up diagonally */
}

.team-card-logo {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  border: 2px solid white;
  top: -26px;
  position: relative;
  left: 74px;
  z-index: 0;
}

.team-card-logo1 {
  height: 100px;
  z-index: 1;
  position: relative;
}



.blog-desc{
  background-color: #211C65;
  padding: 40px 30px;
}
.blog-content h5{
  color: #fff;
  font-size: 32px;
  font-family: 'Oswald';
  text-transform: capitalize;
  margin-bottom: 30px;
  font-weight: 500;
  line-height: 40px;
}
.blog-content p{
  color: #fff;
  font-size: 16px;
  font-family: 'poppins';
  font-weight: 300;
}
.fa-share-alt{
  color: #ffffff80;
  font-size: 22px;
}

.rightsidebar h4{
  font-family: 'oswald';
  color: #fff;
  margin-bottom: 30px;
}
.separate{
  margin: 15px 0px;
  border:  1px solid #ffffff4a;
}

.blog-date p{
  color: #ffffffbd;
  font-weight: 300;
}
.blog-date{
  display: flex;
  justify-content: space-between;
  font-family: 'poppins';
  margin-bottom: 20px;
}
.rightsidebar{
  background-color:#F07C28;
  padding: 20px 15px;
  margin: 0px 20px;
}
.rightblog h5
{
  font-size: 14px;
    color: #fff;
    font-family: 'Poppins';
    line-height: 18px;
}
.wizard-content  p{
  font-size: 14px;
    font-family: 'Poppins';
    /* margin-left: 20px; */
    color: #4f4e4e;
    font-weight: 400;
    width: 77%;
    margin-bottom: 0px;
    margin-top:15px;
}
.CHECKBOXTEXT{
  margin-top: 15px;
}

.highlight{
  font-size: 13px!important;
  color: #e16105f9;
}
.box-divider {
  /* height: 1px; */
  width: 100%;
  /* background-color: #19181746; */
  margin-top: 20px;
  border: 1.2px dotted #e16c19af;
}

.title-top{
  margin-bottom: 20px;
}
.title-top h5{
  font-size:21px;
  font-family: 'Outfit';
}
.otpcode
{
  font-size: 21px;
  font-family: 'Poppins';
  padding: 0px!important;
  font-weight: 600;
  padding: 0px;
  color:#F07C28 ;
  top: 0px!important;
}
.newtab
{
  background: #F07C28;
    padding: 4px 10px;
    border-radius: 13px;
    font-weight: 'Oswald';
    font-size: 13px;
}
.rightblog p{
  color: #fff;
  font-family: 'poppins';
  font-size: 12px;
}


/* about us */

.right-content-about h5{
font-family: 'Oswald';
color: #F07C28;
text-transform: uppercase;
font-size: 40px;
}

.right-content-about h6{
  font-family: 'Oswald';
  color: #fff;
  font-size: 25px;
  margin-top: 9px;
  text-transform: uppercase;
  }
  .imgslider{
    margin: 60px 0px;
  }

  .fa-quote-left{
    font-size: 50px;
    color: #ffffff4f;
    text-align: center;
    margin-bottom: 20px;
  }

  .border{
    border: 2px solid #534DA2;
    border-radius: 300px;
    border-color: #534DA2!important;
    margin: auto;
    display: block;
    width: 80%;
  }

  
  .imgslider img {
    border-radius: 300px;
    filter: grayscale(100%);
    padding: 10px;
    transition: filter 0.6s ease; /* Add transition for smooth effect */
  }
  
  .imgslider img:hover {
    filter: grayscale(10%);
  }
  
  .right-content-about p{
    font-family: 'Outfit'!important;
    color: #fff;
    font-size: 15px!important;
    font-weight: 300;
    margin-top: 30px!important;
    line-height: 28px!important;
    width: 90%;
    text-transform: capitalize;
    }

    .left-content-about h2{
      font-size: 250px;
      color: #ffffffa1;
      font-family: 'Oswald';
    text-align: center;

    }

    .content-quote .caption h4{
      color: #fff;
      font-size: 32px;
      font-family: 'Oswald';
      margin-bottom: 20px;
    }

    .quotes{
      width: 60%;
      margin: auto;
      display: block;
    }

    .content-quote .caption h4{
font-size: 32px;
text-transform: uppercase;
text-align: center;
    }

    .content-quote .caption p{
      color: #fff;
      font-size: 16px;
      text-align: center;
      font-family: 'Outfit';
      margin-bottom: 20px;
    }







    .core-principles {
      background-color: #211C65; /* Dark purple background */
      color: #ffffff;
      padding: 40px 60px 0px 60px;
  }
  
  .core-principles .text-#F07C28 {
      color: #F07C28; /* #F07C28 color for titles */
      font-size: 32px;
    text-transform: uppercase;
      font-family: 'Oswald';
      text-align: left;
      margin-bottom: 20px;
  }
  
  .btop{
    border-top: 1px solid #ffffff53;
  }
  .core-principles  .section-title {
      font-size: 32px;
      color: #fff;
      font-family: 'oswald';
      margin-bottom:40px;
      text-transform: uppercase;
      text-align: left;
  }
  
  .core-principles  p {
    font-size: 16px;
    font-family: 'Outfit';
    text-align: left;
    margin-bottom: 0px;
}

.mbox{
  padding: 50px 30px 50px 0px;
}

.mbox2{
  padding: 50px 30px 50px 20px;
}
.mission-title{
  font-size: 20px;
  font-family: 'Oswald';
  text-transform: capitalize;
  text-align: left;
  color: #F07C28;
  margin-bottom: 20px;
}

.arrowabtract{
  width: 72px;
  margin-top: -8px;
}
  .core-principles  h3 {
    font-size: 27px;
    margin-top: 10px;
    color: #F07C28;
    margin-bottom: 20px;
    font-family: 'Oswald';
    text-transform: uppercase;
  }
  
  .border-right {
      border-right: 1px solid #ffffff53; /* White border on the right */
  }
  
  .core-principles .col-md-4:last-child {
      border-right: none; /* Remove the right border from the last column */
  }
  .imgslider .owl-carousel .item {
    opacity: 0;
    transition: opacity 2.7s ease-in-out, transform 3.7s ease-in-out;
  }
  
  .imgslider .owl-carousel .active .item {
    opacity: 1;
    transform: translateY(0);
  }

#fixture{
  padding: 40px 0px 40px 30px;
  background-color: #fff;
}
.logo-slider .owl-item img {
    display: block;
    width: 100%;
    border: 3px solid #db0a736e;
    border-radius: 65px;

}


.border {
  border: 2px solid #534DA2;
  border-radius: 300px;
  border-color: #534DA2 !important;
  margin: auto;
  display: block;
  width: 80%;
  box-shadow: 0 0 1px 1px #534DA2;/* Glow effect */
  transition: box-shadow 0.3s ease; /* Smooth transition */
}

/* Optional hover effect for stronger glow */
.border:hover {
  box-shadow: 0 0 20px 12px #c0096552;
}

  .fixture-card{
  background-image: url('./Frame 610.png');
  /* background-image: url('./bgbluefixture.png'); */
  
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 20px;
  background-position: center;


  }
  
  .left-fixture h4{
    color: #fff;
    font-size: 15px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 10px;

    font-family: 'Oswald';
    font-weight: 400;
  }

  .venue{
    margin-bottom: 18px;
  }
.center-fixture h5
  {
    margin-top: 20px;
    color: #fff;
    font-family: 'Oswald';
    text-transform: uppercase;
    text-align: center;
    font-size: 18px;
  }
.center-fixture{
  /* background-image: url('./Home//fixturecenter.png'); */
  /* mix-blend-mode: color-burn; */

  position: absolute;
  background-repeat: no-repeat;
  height: 100%;
}




.center-fixture .date{
  margin-right: 40px;
  mix-blend-mode: normal; /* Override blend mode for text */

}


.heading-wkl h3{
  color: #211C65;
  font-size: 28px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Oswald';
  margin-bottom: 35px;
}

.abstractheading{
  width: 50px;
}
.center-fixture  .date{
  position: relative;
  right: 12px;
}
.vs{
  font-family: 'Oswald';
  font-size: 28px;
  position: relative;
  right: 6px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-weight:400;
  margin-top: 20px;
}
  .center-fixture h6
  {
    color: #fff;
    font-family: 'Oswald';
    text-transform: uppercase;
    text-align: center;
    font-weight: 400;
    font-size: 17px;
  }
  .venue p{
    color: #fff;
    font-weight: 400;
    font-family: 'Oswald';
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    margin-bottom: 0px;
    font-family: 'Oswald';
  }
  .left-fixture h3{
    color: #fff;
    font-size: 26px;
    text-transform: uppercase;
    font-family: 'Oswald';
    margin-top: 10px;
    text-align: center;
  }
  .left-fixture img{
    width: 65px!important;
    margin: 30px auto 10px;
  }

  .left-fixture h4{
  white-space: nowrap;
  }


  .lock-close{
    width:28px;
  }
  .table-bordered>:not(caption)>*{
    border-width: 0px;
  }
  .sendotp  span{
    border: 0px;
    background-color: #F07C28;
    pointer-events: auto;
    color: #fff;
    padding: 8px 15px;
    border: 2px solid #fff;
    font-weight: 500;
    font-family: 'poppins';
    border-radius: 10px;
    position: absolute;
    font-size: 13px;
    margin-top: 20px;
  }

.logo-register h5{
  color: #fff;
  font-family: 'outfit';
  text-align: center;
  font-size: 30px;
  margin-bottom: 0px;
}
#registration-form{
  background-image: url('./2 (2).jpg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh ;
}
.logo-register  img{
  margin: auto;
  display: block;
  width: 50%;
  margin-top: 40px;
  margin-bottom: 40px;
}
 .verify  button :disabled {
  background-color: lightgray!important;
 }



 .file-preview{
  margin-top: 20px;
 }
 
.btn-reset{
  padding: 4px 10px;
  font-family: 'Poppins';
  font-size: 12px;
  border: 0px;
  background: #f5c8a6;
  border-radius: 10px;
  margin-top: 10px;
  color: #F07C28;
}
 .sendotp button{
  color: #fff;
  background: transparent;
  border: 0px;
 }
  .verify button{
    
    background:linear-gradient(19deg, rgba(33, 28, 101, 1) 33%, rgb(32 23 180) 100%);
    color: #fff;
    padding: 8px 20px;
    font-family: 'poppins';

    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 20px;
    text-transform: capitalize;
  }
  .form-control:focus{
    border-color: #f07b287b;
    outline: 0;
    box-shadow: none;
  }
.titlestep{
  display:none!important
}
  #registration-form{
    padding: 40px 160px;
  }

  .form-navigation{
    float: right;
    /* position: absolute; */
    bottom: 0;
    right: 6%;
    margin-bottom: 15px;
    margin-top: 10px;
  }
  .wizard-content{
    background-color: #fff;
    background-image: url('./123333333 (3).png');
    height: 100%;
    background-size: cover;
    background-position: top;
    min-height: 470px;
    position: relative;

  }
  
  .swal2-title,.swal2-html-container{
    font-family: 'outfit';
  }

  .swal2-styled{
    background-color: #F07C28;
  }

  .form-control{
    font-family: 'poppins';
  }
label{
  float: right;
  right: 20px;
  top: 11px;
  font-size: 11px;
  text-align: center;
  color: #fff;
  width: 37%;
  font-family: 'Outfit';
  background-color: #F07C28;
  padding: 3px 15px;
  border-radius: 20px;
  position: relative;

}



  .wizard {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .wizard-steps {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
  }
  
  .step {
    text-align: center;
    width: 20%;
    position: relative;
  }
  
  .step::before {
    content: "";
    position: absolute;
    top: 30%;
    left: -50%;
    height: 1px;
    background-color: #ccc;
    width: 100%;
    z-index: -1;
  }
  
  .step:first-child::before {
    display: none;
  }
  
  .step-number {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #ccc;
    line-height: 36px;
    font-size: 16px;
    color: #000;
    font-weight: bold;
  }

  .progressbar li:last-child{
    margin-bottom: 0px;
  }
  
  .step.active .step-number {
    background-color: #F07C28;
    border-color: #F07C28;
    color: white;
  }

  
  .step-label {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    color: white;
    font-family: 'poppins';
    font-weight: 600;
  }
  
  .form-group input{
    color:#f07b28!important;
    box-shadow:rgb(240 124 40 / 40%) 0px 1px 2px;
    font-size: 14px;
    padding: 12px;
    font-weight: 500;
    font-family: 'poppins';
    border:1.1px solid #F07C28;
    width: 100%;
    margin-bottom:15px;
  }
  .registration-title h4{
    color: #211C65;
    margin-top: 20px;
    font-family: 'Outfit';
    font-size: 24px;
    font-weight: 600;

  }
  .form-group{
    /* width: 90%; */
    margin: auto;
    display: block;
  }

  ::placeholder {
    color:#f27b2688!important;
  }
.fit-wdith{
  width: fit-content;
  /* margin-left: 24px; */
  margin-bottom:20px!important;
}
  .lineregistration{
    height:2.5PX;
    width: 46%;
    background-color: #f07c28;
    margin-bottom: 25px;
  }
  .error{
    font-size: 9px;
    font-family: 'Poppins';
    color:#fff;
    background-color: red;
    text-align: center;
  }
  .wizard-content {
    width: 100%;
    padding: 20px 50px;
  }
  
  .wizard-buttons {
    text-align: right;
  }
  
  .progress {
    height: 5px;
    background-color: #ddd;
  }
  
  .progress-bar {
    background-color: #F07C28;
  }

  

  .newcard {
    background: #fff;
    margin-bottom: 0px;
    padding: 0px !important;
}




  
  .btn-#F07C28 {
    background-color:#F07C28;
    color: white;
    border-radius: 5px 0 0 5px;
    border: 2px solid #F07C28;
    font-family: 'Poppins';
    font-weight: 500;
  }

.completed .circle{
  color:#fff!important;
  text-align: center;
  font-family: 'poppins';
  font-size: 20px;
}
  
.progressbar .circle{
  color: #fff;
}
  /* Container for the file upload */
.file-preview {
  width: 54px;
  height: 50px;
  background-image: url('./user.png');
  border-radius: 5px;
  margin-right: 10px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

/* Styling for the button */
.upload-btn {
  display: inline-block;
  width: 66%;
  padding: 14px 15px;
  background-color: #fff;
  color: #e16c19;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'poppins';
  border: 1.1px solid #f07c28;
  font-weight: 500;
  font-size: 13px;
  margin-top:20px;
  text-align: center;
  transition: background-color 0.3s ease;
  right: 20px;
    left: 17px;
    top: -1px;
}
.form-group1{
  width: 100%;
  /* margin-left: 20px; */
  margin-top: 20px;
}

.form-group1  label{
  color: #000!important;
  font-family: 'poppins';
  font-weight: 500!important;
  margin-top: 0px;
}
.form-group1 label{
  font-size: 18px;
}
.form-group1 input
{
  height: 55px!important;
  width: 55px!important;
  color:#e16c19  !important;
  font-size: 20px;
background: 
linear-gradient(287deg, rgb(240 124 40 / 39%) 33%, rgb(240 124 40 / 20%) 100%);
  padding: 13px;
  font-weight: 500;
  font-family: 'poppins';
  border: 0px solid #e16c19;
  margin-bottom: 30px;
}
.form-group1 span{
  padding: 10px;
  font-size: 20px; 
  top: -20px; 
position: relative;
}
.upload-btn:hover {
  background-color: #e57c00;
  color: #fff;
}
.Toastify div{
  font-family: 'poppins';
  font-size: 14px!important;
}

.upload-btn .btn-text {
  margin-left: 10px;
}

.file-input {
  display: none;
}


  
  .btn-white {
    background-color: white;
    color: #2f2c60;  /* Navy-like color */
    border-radius: 0 5px 5px 0;
    border: 2px solid #F07C28;
    font-family: 'Poppins';
    font-weight: 500;

  }
  .btn-white :hover{
    background-color: #fff;
  }
  .dropdown-toggle-split::after {
    margin-left: 10px;
    color: #2f2c60;
  }
    
  .btn-group {
    width: 100%;
    margin-bottom: 30px;
  }
  .btn-group .dropdown-menu {
    border-radius: 5px;
    border-color: #F07C28;
  }

  .wizard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .label{
    color: #fff;
    font-family: 'Outfit';
    margin-left: 20px;
    font-size: 15px;
    top: 2px;
    position: relative;
  }
  

  .addmore{
    background-color: #F07C28;
    pointer-events: auto;
    color: #fff;
    padding: 8px 15px;
    border: 2px solid #fff;
    font-weight: 500;
    font-family: 'poppins';
    border-radius: 10px;
    position: absolute;
    font-size: 13px;
  }
  .vertical-progress-bar {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #F07C28;
  }
  
  .progressbar {
    list-style: none;
    padding-left: 0;
    position: relative;
    margin: auto;
    display: block;
    width: 60%;
  }
  
  .progressbar li {
    position: relative;
    margin-bottom: 40px;
  }
  
  .circle{
    display: none;
  }
  .progressbar li .circle {
    width: 40px;
    height: 40px;
    /* border: 3px dotted #ccc; */
    z-index: 1;
    position: relative;
    border-radius: 50%;
    background-color: white;
    display: inline-block;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  
  .progressbar li.completed .circle {
    border-color: #211C65;
    background-color: #211C65;
    font-family: 'outfit';
    line-height: 38px;
  }
  
  .progressbar li.active .circle {
    border-color: #F07C28;
  }
  
  .circle{
    border-color: #211C65;
    background-color: #211C65;
    font-family: 'outfit';
    line-height: 35px;
  }
  .progressbar li .step-label {
    margin-left: 10px;
    font-size: 16px;
  }
  
  .progressbar li::before {
    content: '';
    position: absolute;
    left:20px;
    top: 25px; /* Adjusts the position to center the line */
    height: 80px;
    width: 2px;
    background: #ccc;
    display: block;
  }
  
  .progressbar li.completed::before {
    background: #211C65;
    
  }
  
  .progressbar li:last-child::before {
    display: none;
  }
  
  .navigation-buttons {
    margin-top: 20px;
  }
  
  .navigation-buttons button {
    margin: 0 10px;
    padding: 10px 20px;
    cursor: pointer;
  }
  

  .next-btn{
    background:linear-gradient(19deg, rgba(33, 28, 101, 1) 33%, rgb(32 23 180) 100%);
    padding: 8px 20px;
    border: 0px;
    font-family: 'Poppins';
    font-weight: 500;
    margin-left: 20px;
  }

.form-select{
  color:#f07c28 !important;
  font-size: 14px;
  padding: 12px;
  font-weight: 500;
  font-family: 'poppins';
  border: 1.2px solid #f07c28;
  box-shadow: rgb(240 124 40 / 40%) 0px 1px 6px;
  margin-bottom:15px;
}
  
.vertical-progress-bar{
  background: radial-gradient(circle, rgba(240,124,40,1) 33%, rgba(234,111,22,1) 100%);

  /* background-image: url('./creative-trendy-modern-white-paper-texture-background 2 (1).png'); */
  padding: 0px;
  background-size: cover;
  background-repeat: no-repeat;
}
  .addmorebtn{
    background: #e16c19;
    padding: 8px 20px;
    border: 0px;
    font-family: 'Poppins';
    font-weight: 500;
    margin-left: 20px;
  }
  .next-btn:hover{
    background: #e16c19;
    padding: 8px 20px;
    border: 0px;
    font-family: 'Poppins';
    font-weight: 500;
    margin-left: 20px;
  }

  .pre-btn{
   background: linear-gradient(19deg, rgba(33, 28, 101, 1) 33%, rgb(32 23 180) 100%);
    padding: 8px 20px;
    border: 0px;
    font-family: 'Poppins';
    font-weight: 500;
  }
  .disable{
    background-color:#5c5b5b7d !important
  }
  
  .pre-btn img
  {
    transform: rotate(180deg);
  }
  .steptitle p{
    display: none;
  }

  .card{
    margin-bottom: 20px;
  }
  
  .card-header h5 {
    font-size: 16px;
    font-family: 'outfit';
  }
.hidedesktop{
  display: none;
} 

.highlight-article h3, #blog-page h3{
  color: #fff;
  text-align: center;
  font-family:'oswald';
}
@media only screen and (max-width: 600px) {
  .hidedesktop{
    display: flex;
  } 
  .highlight-article img{
    margin-bottom: 20px;
    border-radius: 5px;
  }
  #footerimg{
    padding: 20px 10px;
  }
  
  .fit-wdith{
    width: fit-content;
    margin-bottom: 10px !important;
  }
  .wizard-content{
    min-height: 400px;
  }
  .separateexp{
    margin-bottom: 22px;
  }
  .newcard{
    background: #dfdfdf;
    margin-bottom: 15px;
    padding: 10px 0px!important;
  }
  .logo-register h5{
    margin-top: 30%;
    font-size: 22px!important;
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .progressbar li .circle{
    background: #f07c28;
  }
  .progressbar li::before{
    width: 65px!important;
  }
  .progressbar li .circle , .progressbar li.completed .circle{
    line-height: 30px;
  }
  .lineregistration {
    margin-bottom: 10px;
  }
  .btn-danger{
    display: none;
  }

  .steptitle p{
    color: #fff;
    font-family: 'Poppins';
    padding: 10px;
    margin-bottom: 0px;
    margin-top: 16px;
    font-size: 14px;
    display: block;
  }
  .form-select{
    margin-bottom:10px;
    padding: 11px;
    font-size: 14px;
  }
  .form-navigation{
    margin-bottom: 0px;
  }
  .progressbar li{
    margin-bottom: 0px;
  }
  .completed .circle{
    color: #fff!important;
  }
  .progressbar li .circle{
    width: 30px;
    text-align: center;
    height: 30px;
    text-align: center;
    font-size: 14px;
    font-family: 'poppins';
    line-height: 30px;
  }
  .progressbar{
  display:flex;
  width: 100%;
  justify-content: space-between;
  }
  .label{
    font-size: 12px;
    display: none;
  }
  .pre-btn,.next-btn{
    font-size: 12px;
    margin-top: 10px;
  }
  
  ::placeholder {
    color:#f27b26b3!important;
    font-size: 14px;
  }
  .progressbar li::before{
    height: 2px;
    top:15px;
    width: 100px;
    z-index:1; 
  }
  .vertical-progress-bar{
    padding: 7px 30px;
    background: #fff;
    /* background-image: url('./creative-trendy-modern-white-paper-texture-background 2 (1).png'); */
    border-radius:5px;
    /* background-color: radial-gradient(circle, rgba(240,124,40,1) 33%, rgba(234,111,22,1) 100%); */
  }

  #registration-form {
    background-image: url('./333.jpg');
    background-size: contain;
    height: 100vh;
}


  #registration-form{
    padding: 20px 20px;
  }
  .wizard-content{
    padding: 10px;
    border-radius: 5px;
    margin-top: 20px;
  }
  .registration-title h4{
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 10px;
  }
  .fit-wdith{
    margin-bottom: 20px;
  }
  .form-group{
    width:100%;
  }
  .form-group input{
    padding:11px 15px;
    margin-bottom: 10px;
  }

  .titlestep{    color: #fff;
    margin-bottom: 10px;
    font-family: 'Outfit';
      display:block!important;
    font-weight: 400;}
  .Toastify__toast-container{
    width: 80%;
    margin: auto;
  }

  .form-navigation{
    position: relative;
  }
  .CHECKBOXTEXT{
    font-size: 12px!important;
    width: 100%!important;
    display: flex;
  }
  .upload-btn{
    width: 70%!important;
    
    padding: 10px 15px!important;
    font-size: 14px;
    margin-top: 10px;
  }
  .btn-danger{
    display: block;
    margin-top: 5px;
  }
  .form-navigation{
    bottom: 10px;
  }
  .sendotp{
    margin-top: 15px;
  }
  .file-preview{
    margin-right: 0px;
    height: 40px;
    margin-top: 15px;
  }
  .form-group input{
    font-size: 16px!important;
  }
  #fixture{
    padding: 20px 0px 20px 10px;
  }
  .left-fixture h3{
    font-size: 20px;
  }
  .left-fixture h4{
    font-size: 12px;
    font-weight: 500;
  }
  .vs{
    font-size: 18px;
    font-weight: 600;
    margin-top: 35px;

  }
  .center-fixture h5{
    font-size: 14px;
  }
  .center-fixture h6{
    font-size: 12px;
  }
  .venue p{
    font-size: 12px;
  }
  .desktopshow{
    display: none!important;
  }
  .blog-desc{
    padding: 30px 10px;
  }
  .newtab{
    font-size: 12px;
    font-weight: 400;
  }
  .rightblog p{
    margin-bottom: 0px;
  }
  .rightblog {
    border-radius: 10px;
  }
  .blog-content h5{
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .rightblog h5{
    font-size: 12px;
  }
  .rightsidebar{
    margin: 0px;
    border-radius: 2px;
  }
  .border{
    width: 100%;
  }
  .core-principles{
    padding: 10px;
  }
  .core-principles .text-#F07C28{
    font-size: 22px;
  }
  .mbox{
    padding: 15px 10px 15px 0px;
  }
  .mission-title{
    font-size: 18px;
    margin-bottom: 10px;
  }
  .core-principles .section-title{
    font-size: 20px;
    margin-bottom: 25px;
  }
  .core-principles p{
    font-size: 15px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ffffff6c;
  }
  .mbox2{
    padding: 15px 10px 15px 0px;
  }
  .content-quote .caption p{
    font-size: 13px;
  }
  .content-quote .caption h4{
    font-size: 22px;
  }
  .quotes{
    width: 85%;
  }
  .blog-date p{ 
    margin-bottom: 0px;
    font-size: 12px
  }
  .blog-content p{
    font-size: 13px;
  }
  .blog-date{
    margin-bottom: 15px;
  }
  #table {
    overflow: auto;
}
.card{
  margin-bottom: 20px;
}

.card-header h5 {
  font-size: 16px;
  font-family: 'outfit';
}

  #newscard{
  padding: 15px 0px 15px 10px;
  }
  .table td {
    font-size: 10px;
    padding: 8px;
    white-space: nowrap;
}
  #standing{
    padding: 15px 10px;
  }
  .custom-header th {
    font-size: 10px;
    white-space: nowrap;
}
td img {
  width: 35px !important;
}
.table td {
  font-size: 10px;
  padding: 0px 5px;
  white-space: nowrap;
}
  .mobileshow{
    display: block!important;
  }
  .news-caption .heading{
    font-size: 14px;
    margin-bottom: 5px;
  }
  .news-caption .date{
    font-size: 10px;
  }
  .readmore p{
    font-size: 10px ;
  }
  .cardnews{
padding: 10px;
  }
  .news-caption .heading p{
    margin-bottom: 5px;
    font-size: 16px!important;
  }


  
.text-white{
  font-family: 'Oswald';
    text-align: center;
    margin-bottom: 20px;
    font-size: 19px;
}
#aboutus{
  padding: 30px 10px;
}
#aboutus1{
  padding: 20px 10px;
}
.imgslider{
  margin: 30px 0px;
}

.right-content-about p{
width: 100%;
margin-top: 10px!important;
font-size: 14px!important;
line-height: 22px!important;
}

.right-content-about h6{
font-size: 20px;
}
.left-content-about h2{
  font-size: 70px;
}
.right-content-about h5{
  font-size: 28px;
}
.left-content-about{
  display: none;
}
#aboutus img{
border-radius: 5px;
}
#aboutus h4{
  margin-bottom: 20px;
  color: #fff;
  font-family: 'oswald';
  font-size: 18px;
}
#aboutus p{
  color: #fff;
  font-family: 'poppins';
  width: 100%;
  font-weight: 300;
  font-size: 13px;
}
.col-md-11{
  margin-left: 0%;
  width: auto;
}

.navbar-nav  a{
  margin-left: 20px;
  font-size: 13px;
  margin-top: 10px;
}
.abstractnav{
  display: none;
}
.navbar-toggler{
  position: relative;
  border: 0px;
}
.vs{
  right: -8px;
}
.arrowabtract{
  width: 44px;
}
.center-logo1 img{
  width:60px;
  left:10px;
  position: relative;
}
.navbar-collapse {
  background: #2b2762;
    padding: 10px;
    z-index: 99!important;
}
.heading-wkl h3{
  margin-bottom: 20px;
  font-size: 19px;
}
.displaynonemb{
  display: none!important;
}
.tablwidth{
  display: none!important;
}
.form-toss1{
  display: none;
}
.navbar-collapse{
  z-index: 1;
}
.logom img{
  width: 60px;
}
  #highlights{
    padding: 15px 10px;
  }
  .hightlight-caption h5{
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
  }
  .readmore-highlights p{
    margin-top: 8px;
    font-size: 10px;
  }
  .hightlight-caption{
    padding: 5px 14px 0px 0px;
  }

  .team-card-btn{
    width:35%!important;
  }
  .team-card-bengaluru-bison{
    padding-left: 20px;
  }
  .team-card-bengaluru-bison{
    padding: 10px 15px;
  }
  .teamnewcard{
    margin: 0px;
  }
  .team-card-heading{
    font-size: 22px!important;
  }
  .team-card-btn{
    font-size: 12px!important;
  }
  .team-card-logo{
    height: 70px;
    width: 70px;
  }

  .bg-light{
    background: #FFF!important;
    padding: 0px;
  }
  

  #preheader span{
    font-size: 13px;
  }
  .left-caption .heading h4{
    font-size: 16px;
    width: 99%;
  }
  .left-caption .heading p{
    font-size: 12px;
    margin-top: 8px;
    width: 100%;
    margin-bottom: 0px;
  }
  .left-highlight{
    margin-bottom: 20px;
  }
  .hightlightcenter{
    margin-bottom: 20px;
  }

  .footersection img{
    width: 80%;
  }
  .hightlightlist{
    margin-top: 5px;
    margin-bottom: 8px;
  }
  .hightlight-caption .date{
    font-size: 10px;
  }
  .highlights-tabs{
    display: none;
  }
  .footer-navlink {
    width: 100%!important;
    overflow-x: scroll!important;
    padding: 0px;
  }
  .fa-share-alt{
    font-size: 18px;
  }
  #preheader .text-right i{
    margin-left: 10px;
  }

  .content h5 {
    font-size: 20px;
    font-family: "Oswald", sans-serif;
    text-transform: capitalize;
    line-height: 32px;
    width: 92%;
}
.content{
  left: 10px;
  bottom: 20px;
}
  .footersection{
    padding: 20px 15px;
  }

  .title-top{
    margin-top: 20px!important;
    margin-bottom: 20px;
  }
  label{
    font-size: 9px;
  }
  .footer-navlink li a {
    color: #fff;
    margin-left: 24px;
    font-size: 12px;
  }
  .copy-right p{
    font-size: 12px;
    font-weight: 200;
  }

  .owl-dots{
    display: none;
  }
  .breadcrumb-text{
    font-size: 18px;
    left: 22px;
  }
}










.form-select:focus{
  border-color: #f07c28;
    outline: 0;
    box-shadow: rgb(240 124 40 / 40%) 0px 1px 6px;
}



/* Hide the default checkbox appearance */
input[type="checkbox"] {
  appearance: none;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
    top: 5px;
    border: 2px solid #f07c28;
}

/* Checked state */
input[type="checkbox"]:checked {
  background-color: orange; /* Keep orange on check */
  position: relative;
  top: 5px;
}

/* Checkmark icon */
input[type="checkbox"]:checked:before {
  top: 5px;
  content: "";
  position: absolute;
  top: 3px;
  left: 7px;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}


@media only screen and (max-width: 600px) {
  .wizard-content p {
    width: 90%;
    font-size: 12px;
    margin-bottom: 0px;
    font-weight: 500;
    color: #313030;
  }

.box-divider
  {
    /* height: 1px; */
    width: 100%;
    /* background-color: #19181746; */
    margin-top: 20px;
    border: 1.8px dotted #e16c19af;

  }
  .box-divider-exp{
    
    width: 100%;
    /* background-color: #19181746; */
    border: 1.8px dotted #e16c19af;


    margin-top: 20px;
    margin-bottom: 20px;
  }
 
 
  .verify button{
    font-size: 10px!important;
    border: 0px;
    padding: 8px 12px;
  }
.overflox
  {
    height: 400px;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  label{
    right: 10px;
    padding: 3px 10px;
    width: 27%;
  }

 .disable{
    background-color: #9d9d9d!important;
  }
  .wizard-content{
    padding: 10px 15px;
  }
  .sendotp span{
    position: relative;
    width:100%;
    padding: 7px;
    margin-top: 15px;
  }
  .sendotp button{
    font-size: 11px;
  }
  .title-top h5{
    font-size: 15px;
  }
  .form-group1 span{
    font-size: 15px;

  }
  .lock-close{
    width: 20px;
  }

  .logo-register img{
    margin: auto;
    display: block;
    width: 100%;
    margin-top: 25%;
    margin-bottom: 30px;
  }
}


#map{
  background-color: #26225E;
  padding: 40px;
  background-image: url('./Frame 595.png');
  background-size: cover;
}
#map  img{
  padding-bottom: 180px;
}
.boximg{
  display: none;
  width:60%;
  margin: auto;
  display: block;
  border-radius: 227px;
  margin-bottom: 80px;
  margin-top: -200px;
}
.space-between{
  background-color: #26225E;
  padding: 20px;
}

#trialcamp-video{
  background-color: #26225E;
  padding: 40px;
  background-image: url('./Frame 594.svg');
  background-size: cover;
  background-repeat: no-repeat;
}

.trial-capitiom p{
  color: #fff;
  font-family: 'poppins';
  font-weight: 300;
  width: 38%;
  font-size: 15px;
  text-align: center;
  margin: auto;
}

.title-trail h4{
  color: #fff;
  font-family: 'Oswald';
  text-align: center;
  text-transform: uppercase;
  width: 60%;
  font-size: 32px;
  margin: auto;
  line-height: 45px;

}

.arrowcenter img {
  margin: auto;
  display: block;
  margin-top: 20px;
  margin-bottom: 40px;
}
.circle-animation  img{
  margin: auto;
  display: block;
  margin-top: 30px;
}
.error{
  background-color: transparent!important;
  color: red!important;
}
.circle-animation {
  animation: orbit 4s linear infinite; /* Rotate around a fixed point */
}


.table-striped>tbody>tr:nth-of-type(odd)>*{
  background:#211c65a1;

  color: #fff;

}

.table ul{
  margin: 0px;
}

.table-striped>tbody>tr:nth-of-type(even)>*{

  background: #403c78a6;

  color: #fff;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.map{
  display: block;
}

.map1{
  display: none;
}


@media only screen and (max-width: 600px) {
  .map1{
    display: block;
  }
  
  .map{
    display: none;
  }

  #map{
    padding: 10px;
  }
  .title-trail h4 {
    width: 85%;
    font-size: 20px;
    line-height: 30px;
  }
  .trial-capitiom p{
    width:96%;
    font-size: 12px;
  }
  #trialcamp-video{
    padding:20px 10px;
  }
  .boximg{
    width: 90%;
    margin-top: -100px;
    margin-bottom: 20px;
  }
  .arrowcenter img{
    margin-top: 10px;
    margin-bottom: 30px;
  }
  #map img{
    padding-bottom: 20px;
  }
  #trialcamp-video {
    background-size: contain;
  }
}



#footerimg{
  padding: 40px 30px;
}


.InstagramEmbed{
  margin-bottom: 30px;
}
#socialplatform{
  padding: 40px 20px ;
}
.overlay11 span{
  font-family: 'outfit';
  font-weight: 500;
  text-align: left;
}
.card {
  border-radius: 8px;
  overflow: hidden;
  font-family: Arial, sans-serif;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.image-container {
  position: relative;
  height: 340px; /* Fixed height for the image */
  overflow: hidden;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fills the container while maintaining aspect ratio */
}

.overlay11 {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background for better text visibility */
  color: #fff;
  text-align: center; /* Center the text horizontally */
  padding: 10px 0; /* Add some padding around the text */
  font-size: 16px;
  font-weight: bold;
}
